// noinspection JSCheckFunctionSignatures

import taskConverter from 'converters/taskConverter';

// noinspection ES6CheckImport
import {collection, query, onSnapshot, orderBy} from "firebase/firestore";
import { createSlice, createSelector } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";
import Task from "models/Task";


// =========================== Slice ===========================
export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    tasks: []
  },
  reducers: {
    set: (state, action) => {
      state.tasks = action.payload
    },
    remove: (state, action) => {
      state.tasks.filter((task) => task.content !== action.payload)
    }
  },
})

// ============================= Actions =============================
export const { set, remove } = taskSlice.actions

// ============================= Thunks =============================
export const fetchTasks = (clientsIds, filters = []) => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter), ...filters,
    orderBy('order', 'desc'))
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      const taskData = doc.data()
      if (clientsIds.includes(taskData.client_id))
        tasks.push({...taskData, ...{id: doc.id}})
    });
    dispatch(set(tasks))
  })
}

export const searchTasksByIds = (ids) => async dispatch => {
  const q = query(collection(firestore, 'tasks').withConverter(taskConverter), orderBy('order', 'desc'))
  onSnapshot(q, (querySnapshot) => {
    const tasks = [];
    querySnapshot.forEach((doc) => {
      if(ids.includes(doc.id)) {
        tasks.push({...doc.data(), ...{id: doc.id}})
      }
    });
    dispatch(set(tasks))
  })
}

// ============================ Selectors ============================
export const selectTasks = state => state.task.tasks;

export const selectTasksFiltered = createSelector(selectTasks, (tasks) => {
  const filtered = initializeFiltered()
  filtered['All'] = tasks;
  tasks.forEach(task => {
    // This if statement to add to imaginary 'assigned' column
    if(task.status === Task.STATUS.APPROVED && task.assignees_ids?.length > 0)
      filtered.assigned.push(task)
    else
      filtered[task.status].push(task)
  })
  return filtered;
})

export const selectTasksStats = createSelector(selectTasks, selectTasksFiltered,
  (tasks, filteredTasks) => {
    return  {
      total: tasks.length,
      [Task.STATUS.PENDING]: filteredTasks[Task.STATUS.PENDING].length,
      [Task.STATUS.MISSING_INFORMATION]: filteredTasks[Task.STATUS.MISSING_INFORMATION].length,
      [Task.STATUS.REJECTED]: filteredTasks[Task.STATUS.REJECTED].length,
      [Task.STATUS.APPROVED]: filteredTasks[Task.STATUS.APPROVED].length,
      [Task.STATUS.IN_PROGRESS]: filteredTasks[Task.STATUS.IN_PROGRESS].length,
      [Task.STATUS.UPLOADED]: filteredTasks[Task.STATUS.UPLOADED].length,
      [Task.STATUS.DONE]: filteredTasks[Task.STATUS.DONE].length
    }
  })


// =========================== Default Export =========================
export default taskSlice.reducer

// =========================== Helper Methods =========================
function initializeFiltered() {
  return {
    All: [], [Task.STATUS.PENDING]: [], [Task.STATUS.MISSING_INFORMATION]: [], [Task.STATUS.REJECTED]: [],
    [Task.STATUS.APPROVED]: [], assigned: [], [Task.STATUS.IN_PROGRESS]: [], [Task.STATUS.UPLOADED]: [], [Task.STATUS.DONE]: []
  }
}
