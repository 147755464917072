import React from "react";
import ReactDOM from "react-dom";
import { FirebaseAppProvider } from 'reactfire';
import {Provider as ReduxProvider} from "react-redux";
import {ReactNotifications} from 'react-notifications-component'

import store from "store.js";
import App from "App.jsx";

import {firebaseConfig} from "firebase.js";
import 'react-notifications-component/dist/theme.css'

ReactDOM.render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <ReactNotifications />
        <ReduxProvider store={store}>
            <App />
        </ReduxProvider>
    </FirebaseAppProvider>,
    document.getElementById("root")
);
