
export default function KanbanColumn({title, altTitle, emptyImg, emptyText, tasks, KanbanCard, isInitial}) {
  return(
    <div className="column is-one-sixth">
      <div className={`kanban-column ${tasks?.length === 0 && 'is-empty'}`}>
        <div className="column-title">
          <h3><span className="column-name">{altTitle || title}</span></h3>
          <h3 className="has-text-black" style={{fontSize: '20px'}}>{tasks?.length}</h3>
        </div>
        <img className="empty-state theme-image" src={emptyImg} alt="..." />
        <p className="empty-text">{emptyText}</p>

        {tasks?.map((task, index) => <KanbanCard task={task} key={index} isInitial={isInitial} title={title} />)}

      </div>
    </div>
  )
}
