import {useState} from "react";
import {Link, NavLink, useHistory} from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import {useAuth} from "contexts/AuthContext";
import {useLayout} from "contexts/LayoutContext";

import Alert from 'components/Alert'

import routes from "routes.js";
import logo from "assets/img/logos/logo/logo.png"
import logoLight from "assets/img/logos/logo/logo-light.png"
import useDropdown from "hooks/useDropdown";
import constants from 'constants.js';

export default function Navbar() {
  // Hooks
  const { currentUser } = useAuth()
  const {toggleSidebar, activeSidebarClass} = useLayout()
  const [popUpIsOpen, togglePopup] = useDropdown()

  // States
  const [activePath, setActivePath] = useState('/')
  const activeRoute = routes.filter(route => route.path === activePath && route.layout === constants.TEAM_LEADER)?.[0]

  // Methods
  const getSidebarItems = (isExtended) => {
    return routes.map((prop, key) => {
      if (prop['layout'] === constants.TEAM_LEADER && !prop['hide']) {
        return( <NavBarItem isExtended={isExtended} prop={prop} key={key} setActiveRoute={setActivePath} />)
      } else {
        return null;
      }
    });
  };

  return(
    <>
      {/* Mobile navbar */}
      <nav className="navbar mobile-navbar no-shadow is-hidden-desktop is-hidden-tablet" aria-label="main navigation">
        <div className="container">
          <div className="navbar-brand">
            <div className="brand-start">
              <div className={`navbar-burger ${activeSidebarClass()}`} onClick={toggleSidebar}>
                <span/>
                <span/>
                <span/>
              </div>
            </div>

            <a className="navbar-item is-brand" href="/">
              <img className="light-image" src={logo} alt="" />
              <img className="dark-image" src={logoLight} alt="" />
            </a>

            <div className="brand-end">

              <div className={`dropdown is-right is-spaced dropdown-trigger user-dropdown ${popUpIsOpen && 'is-active'}`}
                   onClick={togglePopup}>
                <div className="is-trigger" aria-haspopup="true">
                  <div className="profile-avatar">
                    <img className="avatar" src={currentUser.photoURL} alt="" />
                  </div>
                </div>
                <PopUpMenu />
              </div>
            </div>

          </div>
        </div>
      </nav>

      {/* Mobile sidebar */}
      <div className={`mobile-main-sidebar ${activeSidebarClass()}`}>
        <div className="inner">
          <ul className="icon-side-menu">
            {getSidebarItems(false)}
          </ul>
        </div>
      </div>

      {/* Mobile Sub-Sidebar */}
      <div className={`mobile-subsidebar ${activeRoute?.subRoutes && activeSidebarClass()}`}>
        <div className="inner">
          <div className="sidebar-title">
            <h3>Grafix Shop</h3>
          </div>

          <ul className="submenu" data-simplebar="init">
            <div className="simplebar-wrapper" style={{margin: '0px -20px -20px'}}>
              <div className="simplebar-height-auto-observer-wrapper">
                <div className="simplebar-height-auto-observer"/>
              </div>
              <div className="simplebar-mask">
                <div className="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                  <div className="simplebar-content-wrapper" style={{height: '100%', overflow: 'hidden'}}>
                    <div className="simplebar-content" style={{padding: '0px 20px 20px'}}>
                      <li className="divider"/>
                      {
                        activeRoute?.subRoutes?.map((subRoute, index) => (
                          <li key={index}>
                            <Link to={subRoute.path}>{subRoute.title}</Link>
                          </li>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      {/* Main Sidebar */}
      <div className="main-sidebar">
        <div className="sidebar-brand m-auto">
          <Link to="coordinate">
            <img className="light-image" src={logo} alt="" />
            <img className="dark-image" src={logoLight} alt="" />
          </Link>
        </div>
        <div className="sidebar-inner">
          <br />
          <ul className="icon-menu">
            {getSidebarItems(true)}
          </ul>

          <ul className="bottom-menu">
            <li id="user-menu">
              <div id="profile-menu" className={`dropdown profile-dropdown dropdown-trigger is-spaced is-up ${ popUpIsOpen && 'is-active'}`}
                   onClick={togglePopup}>
                <img src={currentUser.photoURL} alt="" />
                <PopUpMenu />
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Sub-Sidebar */}
      <div id="layouts-sidebar" className={`sidebar-panel is-generic ${activeSidebarClass()}`}>
        <div className="subpanel-header">

          <div className="dropdown project-dropdown dropdown-trigger is-spaced">
            <div className="h-avatar is-small">
              <img className="avatar" src={currentUser.photoURL} alt="" />
            </div>
          </div>
          <h3 className="no-mb">Grafix Shop</h3>
          <div className="panel-close">
            <FeatherIcon icon={'x'} />
          </div>
        </div>
        <div className="inner" data-simplebar="init">
          <div className="simplebar-wrapper" style={{margin: '0px'}}>
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"/>
            </div>
            <div className="simplebar-mask">
              <div className="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                <div className="simplebar-content-wrapper" style={{height: '100%', overflow: 'hidden'}}>
                  <div className="simplebar-content" style={{padding: '0px'}}>

                    <ul>
                      <li className="divider"/>
                      {
                        activeRoute?.subRoutes?.map((subRoute, index) => (
                          <li key={index}>
                            <Link to={subRoute.path}>{subRoute.title}</Link>
                          </li>
                        ))
                      }
                    </ul>

                  </div>
                </div>
              </div>
            </div>
            <div className="simplebar-placeholder" style={{width: 'auto', height: '643px'}}/>
          </div>
          <div className="simplebar-track simplebar-horizontal" style={{visibility: 'hidden'}}>
            <div className="simplebar-scrollbar" style={{width: '0px', display: 'none'}}/>
          </div>
          <div className="simplebar-track simplebar-vertical" style={{visibility: 'hidden'}}>
            <div className="simplebar-scrollbar" style={{height: '0px', display: 'none'}}/>
          </div>
        </div>
      </div>
    </>
  )
}


function PopUpMenu() {

  const { currentUser, logout } = useAuth()

  const [error, setError] = useState('')
  const history = useHistory()

  async function handleLogout() {
    setError('')
    try {
      await logout()
      return setTimeout(() => history.push('/auth'), 1000)
    } catch (e) {
      setError(e.message)
    }
  }

  return(
    <div className="dropdown-menu" role="menu">
      <div className="dropdown-content">
        <div className="dropdown-head">
          <div className="h-avatar is-large">
            <img className="avatar" src={currentUser.photoURL} alt="" />
          </div>
          <div className="meta">
            <span>{currentUser.displayName}</span>
            <span>{currentUser.data.role}</span>
          </div>
        </div>
        <hr className="dropdown-divider" />
        <Link to={`/profile/edit`} className="dropdown-item is-media">
          <div className="icon">
            <i className="lnil lnil-user-alt"/>
          </div>
          <div className="meta">
            <span>Edit Profile</span>
            <span>Change your profile information</span>
          </div>
        </Link>
        <hr className="dropdown-divider" />
        {error && <Alert variant='danger'>{error}</Alert>}
        <div className="dropdown-item is-button">
          <button className="button h-button is-primary is-raised is-fullwidth logout-button" onClick={handleLogout}>
            <span className="icon is-small">
              <FeatherIcon icon={'log-out'} />
            </span>
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>
  )
}


function NavBarItem({isExtended, prop, setActiveRoute}) {
  const {toggleSidebar} = useLayout()

  const handleClick = () => {
    setActiveRoute(prop.path)
    prop.subRoutes && toggleSidebar()
  }

  if(isExtended) {
    return (
      <li>
        <NavLink exact to={prop.subRoutes ? '#' : prop.path} data-content="Layouts"
                 activeClassName={prop.subRoutes ? '' : "is-selected is-active"}
                 onClick={handleClick}>
          <div className={'is-flex is-justify-content-space-between is-align-content-center'}>
            <div className={'column is-4'}>
              <FeatherIcon icon={prop.icon} className="sidebar-icon sidebar-svg" />
            </div>
            <div className={'column is-8 m-auto nav-item-title'}>{prop.name}</div>
          </div>
        </NavLink>
      </li>
    )
  } else {

    return(
      <li>
        <NavLink exact to={prop.subRoutes ? prop?.subRoutes?.[0]?.path : prop.path} data-content="Layouts"
                 activeClassName={prop.subRoutes ? '' : "is-selected is-active"}
                 onClick={handleClick}>
          <FeatherIcon icon={prop.icon} className="sidebar-icon sidebar-svg" />
        </NavLink>
      </li>
    )
  }
}
