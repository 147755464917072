// Auth Views
import Login from 'views/auth/Login';

import EditProfile from 'views/EditProfile';
// Admin Views
// import SignUpRequests from 'views/signUpRequests/Index';
// import Inquiries from 'views/inquiries/Index';
import TaskIndex from 'views/tasks/TasksIndex';
import PlansIndex from 'views/plans/PlansIndex';
// import ClientsIndex from "views/users/ClientsIndex";
// import MembersIndex from "views/users/MembersIndex";

import constants from 'constants.js'

const routes = [
  {
    path: '/',
    name: 'Tasks',
    icon: 'list',
    component: TaskIndex,
    layout: constants.TEAM_LEADER,
  },
  {
    path: '/plans',
    name: 'Plans',
    icon: 'book-open',
    component: PlansIndex,
    layout: constants.TEAM_LEADER,
  },
  // {
  //   path: '/signUpRequests',
  //   name: 'Signup Requests',
  //   icon: 'user-plus',
  //   component: SignUpRequests,
  //   layout: constants.ADMIN,
  //   hide: true
  // },
  // {
  //   path: '/inquiries',
  //   name: 'Inquiries',
  //   icon: 'help-circle',
  //   component: Inquiries,
  //   layout: constants.ADMIN,
  //   hide: true
  // },
  // {
  //   path: '/landingPage',
  //   name: 'Landing Page',
  //   icon: 'home',
  //   layout: constants.ADMIN,
  //   subRoutes: [
  //     {
  //       title: "Signup Requests",
  //       path: '/signUpRequests'
  //     },
  //     {
  //       title: "Inquiries",
  //       path: '/inquiries'
  //     }
  //   ]
  // },
  // {
  //   path: '/clients',
  //   name: 'Clients',
  //   icon: 'list',
  //   component: ClientsIndex,
  //   layout: constants.ADMIN,
  //   hide: true
  // },
  // {
  //   path: '/members',
  //   name: 'Members',
  //   icon: 'list',
  //   component: MembersIndex,
  //   layout: constants.ADMIN,
  //   hide: true
  // },
  // {
  //   path: '/users',
  //   name: 'Users',
  //   icon: 'users',
  //   layout: constants.ADMIN,
  //   subRoutes: [
  //     {
  //       title: "Clients",
  //       path: '/clients'
  //     },
  //     {
  //       title: "Members",
  //       path: '/members'
  //     }
  //   ]
  // },
  {
    path: '/profile/edit',
    name: 'Edit Profile',
    icon: 'user',
    component: EditProfile,
    layout: constants.TEAM_LEADER,
    hide: true
  },
  // ========================================= Auth routes =========================================
  {
    path: '/login',
    name: 'Login',
    icon: 'login',
    component: Login,
    layout: constants.AUTH,
  },
];
export default routes;
