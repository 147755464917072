// noinspection ES6CheckImport

import { addDoc, getDoc, updateDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import swal from 'sweetalert';

export const create = async (collectionRef, data) => {
  await addDoc(collectionRef, data)
  await swal('Success', `Success!`, 'success')
}

export const read = async (docRef) => {
  const docSnap = await getDoc(docRef)
  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    await swal('Warning', "Not found", 'warning')
  }
}

export const readAll =  (query, dataSetter, key= 'count') => {
  onSnapshot(query, (querySnapshot) => {
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({...doc.data(), ...{id: doc.id}})
    });
    // noinspection JSCheckFunctionSignatures
    localStorage.setItem(key, data.length)
    dataSetter(data)
  })
}

export const update = async (docRef, data) => {
  await updateDoc(docRef, data)
  await swal('Success', `Updated!`, 'success', {timer: 1000})
}

export const deleteDocument = async (docRef) => {
  await deleteDoc(docRef)
  await swal('Success', `Deleted!`, 'success', {timer: 1000})
}
