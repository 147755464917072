// =========================================== Imports ===========================================
import TasksStatsHeader from "components/TasksStatsHeader"
import {useSelector} from 'react-redux'
import {selectTasksFiltered,} from "slices/taskSlice";
import "views/custom.scss"
import {useState} from "react";
import TasksIndexToolbar from 'views/tasks/TasksIndexToolbar';
import KanbanColumn from "./KanbanColumn";
import Task from "models/Task";
import KanbanCard from "views/tasks/KanbanCard";
import TaskRow from 'views/tasks/TaskRow';

// =====================================================================


export default function TasksIndex() {

  const [activeView, setActiveView] = useState('kanban')


  let filteredTasks = useSelector(selectTasksFiltered)

  return(
    <div className="page-content-inner">

      <TasksStatsHeader />

      {/*Toolbar*/}

      <TasksIndexToolbar activeView={activeView} setActiveView={setActiveView} />


      {
        activeView === 'kanban' ?
          <KanbanView filteredTasks={filteredTasks} KanbanCard={KanbanCard} /> :
          <TableView tasks={filteredTasks?.All} />
      }
    </div>
  )
}

function TableView({tasks}) {

  return(
    <div className="columns tasks-table">
      <div className="column is-12" style={{width: 'fit-content', flexGrow: 1}}>
        <div className="s-card demo-table">
          <table className="table is-hoverable is-fullwidth">
            <tbody>
            <tr>
              <th>Task ID</th>
              <th>Creation Date</th>
              <th>Title</th>
              <th>Tasks Content</th>
              <th>Status</th>
              <th className="is-end">
                <div className="dark-inverted">
                  Actions
                </div>
              </th>
            </tr>
            { tasks?.map( (task, index) => <TaskRow key={index} task={task} />) }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function KanbanView({filteredTasks, KanbanCard}) {

  return(
    <div className="columns is-kanban-wrapper">
      <KanbanColumn
        title={'Pending'} tasks={filteredTasks[Task.STATUS.PENDING]}
        emptyImg={require('assets/img/illustrations/projects/board/new-dark.svg').default}
        emptyText={'There are no pending tasks.'}
        KanbanCard={KanbanCard}
        isInitial={true}
      />

      <KanbanColumn
        title={'Missing Information'}
        tasks={filteredTasks[Task.STATUS.MISSING_INFORMATION]}
        emptyImg={require('assets/img/illustrations/projects/board/test-dark.svg').default}
        emptyText={'There are no tasks missing information.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Approved'} tasks={filteredTasks[Task.STATUS.APPROVED]}
        emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
        emptyText={'There are no approved tasks.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Assigned'} tasks={filteredTasks.assigned}
        emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
        emptyText={'There are no approved tasks.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'In Progress'} tasks={filteredTasks[Task.STATUS.IN_PROGRESS]}
        emptyImg={require('assets/img/illustrations/projects/board/progress.svg').default}
        emptyText={'There are no tasks in progress at the moment.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Uploaded'} tasks={filteredTasks[Task.STATUS.UPLOADED]}
        emptyImg={require('assets/img/illustrations/projects/board/ready-dark.svg').default}
        emptyText={'There are no uploaded tasks at the moment.'}
        KanbanCard={KanbanCard}
      />

      <KanbanColumn
        title={'Done'} tasks={filteredTasks[Task.STATUS.DONE]}
        emptyImg={require('assets/img/illustrations/projects/board/complete-dark.svg').default}
        emptyText={'There are no uploaded tasks at the moment.'}
        KanbanCard={KanbanCard}
      />
    </div>
  )
}
