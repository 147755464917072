import { useAuth } from "contexts/AuthContext";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Alert from "components/Alert";
import FeatherIcon from 'feather-icons-react';

import station from "assets/img/illustrations/login/station.png"
import logo from "assets/img/logos/logo/logo.png"
import logoLight from "assets/img/logos/logo/logo-light.png"
import swal from 'sweetalert';

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()

  const { login, resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  async function handleSubmit(e) {
    e.preventDefault()
    try {
      setError('')
      setLoading(true)
      await login(emailRef.current["value"], passwordRef.current["value"])
      return setTimeout(() => history.push(`/`), 1000)
    } catch (e) {
      setError(e.message)
      passwordRef.current.value = ''
    }
    setLoading(false)
  }

  async function handlePasswordReset(e) {
    e.preventDefault()
    try {
      setError('')
      setLoading(true)
      let email = prompt("Please enter your email")
      if(email != null) {
        await resetPassword(email)
        await swal('Success', `An email has been sent to you with a reset link`, 'success')
      }
    } catch (e) {
      setError(e.message)
    }
    setLoading(false)
  }


  return(
    <div className="auth-wrapper">

      <div className="modern-login">

        <div className="underlay h-hidden-mobile h-hidden-tablet-p"/>

        <div className="columns is-gapless is-vcentered">
          <div className="column is-relative is-8 h-hidden-mobile h-hidden-tablet-p">
            <div className="hero is-fullheight is-image">
              <div className="hero-body">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      <img className="hero-image"
                           src={station} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-4 is-relative">
            <a className="top-logo" href="/">
              <img className="light-image" src={logo} alt="" />
              <img className="dark-image" src={logoLight} alt="" />
            </a>
            <div className="is-form">
              <div className="hero-body">
                <div className="form-text mb-5">
                  <h2>Sign In</h2>
                  <p>Welcome to Grafix Shop.</p>
                </div>
                {error && <Alert variant="danger">{error}</Alert>}
                <div className="form-text is-hidden">
                  <h2>Recover Account</h2>
                  <p>Reset your account password.</p>
                </div>
                <form id="login-form" className="login-wrapper" onSubmit={handleSubmit}>
                  <div className="control has-validation">
                    <input type="text" className="input" placeholder="" ref={emailRef} />
                    <small className="error-text">This is a required field</small>
                    <div className="auth-label">Email Address</div>
                    <div className="auth-icon">
                      <i className="lnil lnil-envelope"/>
                    </div>
                    <div className="validation-icon is-success">
                      <div className="icon-wrapper">
                        <FeatherIcon icon={'check'} />
                      </div>
                    </div>
                    <div className="validation-icon is-error">
                      <div className="icon-wrapper">
                        <FeatherIcon icon={'x'} />
                      </div>
                    </div>
                  </div>
                  <div className="control has-validation">
                    <input type="password" className="input" ref={passwordRef} />
                    <div className="auth-label">Password</div>
                    <div className="auth-icon">
                      <i className="lnil lnil-lock-alt"/>
                    </div>
                  </div>
                  <div className="button-wrap has-help mt-5">
                    <button id="login-submit" type="submit" disabled={loading}
                            className="button h-button is-big is-rounded is-primary is-bold is-raised">
                      Login Now
                    </button>
                    <span><a href="#" onClick={handlePasswordReset}>Forgot your password?</a></span>
                  </div>
                </form>

                <form id="recover-form" className="login-wrapper is-hidden">
                  <p className="recover-text">Enter your email and click on the confirm button to
                    reset your password. We'll send you an email detailing the steps to complete
                    the procedure.</p>
                  <div className="control has-validation">
                    <input type="text" className="input" />
                    <small className="error-text">This is a required field</small>
                    <div className="auth-label">Email Address</div>
                    <div className="auth-icon">
                      <i className="lnil lnil-envelope"/>
                    </div>
                    <div className="validation-icon is-success">
                      <div className="icon-wrapper">
                        <FeatherIcon icon={'check'} />
                      </div>
                    </div>
                    <div className="validation-icon is-error">
                      <div className="icon-wrapper">
                        <FeatherIcon icon={'x'} />
                      </div>
                    </div>
                  </div>
                  <div className="button-wrap">
                    <button id="cancel-recover" type="button"
                            className="button h-button is-white is-big is-rounded is-lower">Cancel
                    </button>
                    <button type="button"
                            className="button h-button is-solid is-big is-rounded is-lower is-raised is-colored">Confirm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
