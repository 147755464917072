// noinspection ES6CheckImport
import {collection, query, onSnapshot, where} from "firebase/firestore";
import {createSlice} from '@reduxjs/toolkit'
import {firestore} from "firebase.js";
import Fuse from 'fuse.js'
import store from "store.js";

const options = {
  includeScore: true,
  keys: ['displayName', 'email']
}

// =========================== Slice ===========================
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    clients: [],
    members: []
  },
  reducers: {
    set_clients: (state, action) => {
      state.clients = action.payload
    },
    set_members: (state, action) => {
      state.members = action.payload
    }
  },
})

// ============================= Actions =============================
// noinspection JSUnusedGlobalSymbols
export const { set_clients, set_members } = userSlice.actions;

// ============================= Thunks =============================
export const fetchUsersByRole = (role, ids) => async dispatch => {
  const q = query(collection(firestore, 'users'), where('role', '==', role),
    where('id', 'in', ids))
  onSnapshot(q, (querySnapshot) => {
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(eval(`set_${role}s`)(users))
  })
}

export const searchUsers = (searchTerm, role)  => async () => {
  const users = store.getState().user[`${role}s`]
  const fuse = new Fuse(users, options)
  const result = fuse.search(searchTerm)
  return searchTerm === '' ? users : result.map(item => item.item);
}

// ============================ Selectors ============================
export const selectUsersByRole = role => state => state.user[`${role}s`]


// =========================== Default Export =========================
export default userSlice.reducer
