import planConverter from 'converters/planConverter';

// noinspection ES6CheckImport
import {collection, query, onSnapshot, where} from "firebase/firestore";
import { createSlice } from '@reduxjs/toolkit'
import {firestore} from "firebase.js";


// =========================== Slice ===========================
export const planSlice = createSlice({
  name: 'plan',
  initialState: {
    plans: [],
  },
  reducers: {
    set: (state, action) => {
      state.plans = action.payload
    }
  },
})

// ============================= Actions =============================
export const { set } = planSlice.actions;

// ============================= Thunks =============================
export const fetchAllPlans = (clientIds) => async dispatch => {
  const q = query(collection(firestore, 'plans').withConverter(planConverter),
    where('client_id', 'in', clientIds))
  onSnapshot(q, (querySnapshot) => {
    const plans = [];
    querySnapshot.forEach((doc) => {
      plans.push({...doc.data(), ...{id: doc.id}})
    });
    dispatch(set(plans))
  })
};

// ============================ Selectors ============================
export const selectPlans = state => state.plan.plans;


// =========================== Default Export =========================
export default planSlice.reducer
