import algoliasearch from 'algoliasearch';
import DatePicker from 'components/DatePicker';
import DropdownMenu from 'components/DropdownMenu';
import {useAuth} from 'contexts/AuthContext';
import FeatherIcon from 'feather-icons-react';
import {orderBy, where} from 'firebase/firestore';
import useDropdown from 'hooks/useDropdown';
import Task from 'models/Task';
import moment from 'moment';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTasks, searchTasksByIds} from 'slices/taskSlice';
import {fetchUsersByRole, selectUsersByRole} from 'slices/userSlice';
const client = algoliasearch(process.env.REACT_APP_ALGOLIA_SEARCH_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY)
const index = client.initIndex('GrafixShop');

export default function TasksIndexToolbar({activeView, setActiveView}) {

  const statusFilteringIsActive = activeView === 'table';
  const dispatch = useDispatch()
  const searchTermRef = useRef()
  const statuses = Task.MainStatusArray
  const [clientsDropdownIsOpen, toggleClientsDropdown] = useDropdown()
  const [membersDropdownIsOpen, toggleMembersDropdown] = useDropdown()
  const {currentUser} = useAuth()

  // Fetches all clients, which are used for filtering
  useEffect(() => {
    dispatch(fetchUsersByRole('client', currentUser?.data?.clients))
    dispatch(fetchUsersByRole('member', currentUser?.data?.members))
  }, [])
  const clients = useSelector(selectUsersByRole('client'))
  const members = useSelector(selectUsersByRole('member'))

  const [filters, setFilters] = useState({
    creation_date: {
      start: moment().startOf('month').toDate()
    },
    status: null,
    clients_ids: [],
    members_ids: [],
    leftovers: false,
  })

  useEffect(() => {
    const filtersArray = [];
    if (filters.leftovers) {
      filtersArray.push(
        where("creation_date", '<', moment().startOf('month').toDate()),
        where('status', 'in', [
          Task.STATUS.PENDING, Task.STATUS.MISSING_INFORMATION, Task.STATUS.APPROVED, Task.STATUS.IN_PROGRESS]),
        orderBy('creation_date', 'desc'))
    } else {
      if(filters.status) filtersArray.push(where('status', '==', filters.status))
      if(filters.clients_ids.length > 0) filtersArray.push(where('client_id', 'in', filters.clients_ids))
      else if(filters.members_ids.length > 0)
        filtersArray.push(where('assignees_ids', 'array-contains-any', filters.members_ids))
      if(filters.creation_date) {
        if(filters.creation_date.start)
          filtersArray.push(where("creation_date", '>=', filters.creation_date.start))
        if(filters.creation_date.end)
          filtersArray.push(where('creation_date', '<', filters.creation_date.end))
        filtersArray.push(orderBy('creation_date', 'desc'))
      }
    }
    dispatch(fetchTasks(currentUser?.data?.clients, filtersArray))
  }, [filters])

  useEffect(() => {
    setFilters({...filters, ...{status: null}})
  }, [statusFilteringIsActive])

  const handleUsersFilter = (userID, filterName) => {
    if(filters[filterName].includes(userID)) {
      setFilters(prevState =>
        ({...filters, ...{[filterName]: prevState[filterName].filter(user_id => user_id !== userID)}}))
    } else {
      setFilters( prevState => ({...filters, ...{[filterName]: prevState[filterName].concat(userID)}}))
    }
  }

  const handleCreationDateFilter = (date) => {
    const [start, end] = date;
    setFilters({
      ...filters,
      ...{creation_date: {start,
          end: end && moment(end).endOf('month').toDate()}}})
  }

  function handleSubmit(e) {
    e.preventDefault()
    const searchTerm = searchTermRef.current["value"];
    if(searchTerm === '') {
      dispatch(fetchTasks())
    } else {
      index.search(searchTerm, {
        attributesToRetrieve: ['objectID'],
        hitsPerPage: 50
      }).then(({hits}) => {
        const ids = hits.map((hit) => hit.objectID)
        dispatch(searchTasksByIds(ids))
      })
    }
  }

  function defaultFilters() {
    setFilters({
      creation_date: {
        start: moment().startOf('month').toDate()
      },
      status: null,
      clients_ids: [],
      members_ids: [],
      leftovers: false,
    })
  }

  const renderActiveUsers = (users, filter_name) => {
    return users.filter(user => filters?.[filter_name].includes(user?.id)).map( (user, index) => (
      <div className="h-avatar is-small hint--primary hint--top" key={index}
           data-hint={`${user?.displayName} - ${user?.['abbreviation']} - ${user?.email}`}
           onClick={() => handleUsersFilter(user?.id, filter_name)}>
        <img className='avatar avatar-thumb' src={user?.photoURL || "https://via.placeholder.com/150x150"} alt=""/>
      </div>
    ))
  }

  const renderInActiveUsers = (users, filter_name) => {
    return users.filter(user => !filters?.[filter_name].includes(user?.id)).map( (user, index) => (
        <a href="#" className="dropdown-item font-size-base is-flex hint--primary hint--top"
           data-hint={`${user?.displayName} - ${user?.['abbreviation']} - ${user?.email}`}
           onClick={() => handleUsersFilter(user?.id, filter_name)} key={index}>
          <div className="h-avatar is-small is-bordered">
            <img className={`avatar avatar-thumb ${filters?.[filter_name].includes(user?.id) && 'is-active'}`}
                 src={user?.photoURL || "https://via.placeholder.com/150x150"} alt="" />
          </div>
          <div className="ml-2 my-auto">
            {user.displayName}
          </div>
        </a>
      )
    )
  }

  return(
    <>
      <div className="kanban-toolbar columns is-vcentered is-multiline is-justify-content-space-between">
        <div className="column is-flex-inline has-gaps">
          <button className="button h-button is-danger mt-3 is-outlined" onClick={toggleClientsDropdown}>Clients</button>
          <div className={`dropdown is-dots dropdown-trigger ${clientsDropdownIsOpen && 'is-active'}`}>
            <div className="dropdown-menu" role="menu">
              <div className="dropdown-content">
                {renderInActiveUsers(clients, 'clients_ids')}
              </div>
            </div>
          </div>
          <div className="column is-flex is-one-third">
            <div className="avatar-stack" style={{marginLeft: 'unset', marginRight: '16px'}}>
              {renderActiveUsers(clients, 'clients_ids')}
            </div>
          </div>
        </div>
        <div className="column is-flex-inline has-gaps">
          <button className="button h-button is-danger mt-3 is-outlined"
                  onClick={toggleMembersDropdown}>Members</button>
          <div className={`dropdown is-dots dropdown-trigger ${membersDropdownIsOpen && 'is-active'}`}>
            <div className="dropdown-menu" role="menu">
              <div className="dropdown-content">
                {renderInActiveUsers(members, 'members_ids')}
              </div>
            </div>
          </div>
          <div className="column is-flex is-one-third">
            <div className="avatar-stack" style={{marginLeft: 'unset', marginRight: '16px'}}>
              {renderActiveUsers(members, 'members_ids')}
            </div>
          </div>
        </div>

        <div className="control has-icon" style={{minWidth: '212px'}}>
          <form onSubmit={handleSubmit}>
            <input className="input" placeholder="Search..." ref={searchTermRef} />
          </form>
          <a className="form-icon" href='#' onClick={handleSubmit}>
            <FeatherIcon icon={'search'} />
          </a>
        </div>
      </div>
      <div className="kanban-toolbar columns is-vcentered is-multiline is-justify-content-space-between">
        <div className="column is-flex-inline has-gaps">
          <DatePicker
            selected={filters.creation_date?.start}
            onChange={handleCreationDateFilter}
            startDate={filters.creation_date?.start}
            endDate={filters.creation_date?.end}
            selectsRange
            showMonthYearPicker
            disabled={filters.leftovers}
          />
          {statusFilteringIsActive && <DropdownMenu title="Status" selectedOption={filters.status}
                                                    disabled={filters.leftovers}
                                                    options={statuses} color="primary is-outlined"
                                                    setActive={(status) => setFilters({...filters, ...{status}})}/>}
          <button className={`button h-button ${filters.leftovers ? 'is-success':'is-primary'} is-raised is-outlined`}
                  onClick={() => setFilters({...filters,
                    ...{leftovers: !filters.leftovers, clients_ids: [], members_ids: []}})}
                  style={{padding: '8px 10px'}}>
            Previous Tasks
          </button>
          <a href={'#'} className={`button h-button is-info is-raised`}  onClick={() => defaultFilters()}>
            <span>Default</span>
          </a>
        </div>
        <div className="field has-addons is-flex is-justify-content-end column is-one-fifth">
          <p className="control">
            <button className={`button h-button is-raised ${activeView === 'kanban' && 'is-primary'}`}
                    onClick={() => setActiveView('kanban')}>
              <span className="icon is-small">
                <i className="fas fa-columns"/>
              </span>
              <span>Kanban</span>
            </button>
          </p>
          <p className="control">
            <button className={`button h-button is-raised ${activeView === 'table' && 'is-primary'}`}
                    onClick={() => setActiveView('table')}>
              <span className="icon is-small">
                <i className="fas fa-list"/>
              </span>
              <span>Table</span>
            </button>
          </p>
        </div>
      </div>
    </>
  )
}
