import MultiSelectPicker from "components/MultiSelectPicker";
import SelectInput from "components/SelectInput";
import {useAuth} from 'contexts/AuthContext';
import {useButtonListener} from 'hooks/utils';
import {useDispatch, useSelector} from 'react-redux';
import {useFirestore} from "reactfire";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import Task from "models/Task";
import {doc, getDoc} from "firebase/firestore";
import {update} from "API.js";
import Input from "components/Input";
import {fetchUsersByRole, selectUsersByRole} from 'slices/userSlice';
import swal from 'sweetalert';
import MediaTab from 'views/tasks/MediaTab';

export default function Update({ task, setIsOpen}) {

  // Constants
  const firestore = useFirestore();
  const dispatch = useDispatch()
  const {currentUser} = useAuth()

  const [activeTab, setActiveTab] = useState('basic-details')
  const [designSizes, setDesignSizes] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const { register, handleSubmit, reset, control, formState } = useForm({
    defaultValues: task
  })
  const { isDirty } = formState;

  // Get Client and Assignee
  useEffect(() => {
    getDoc(doc(firestore, "users", task.client_id))
      .then((docSnap) => {
        if (docSnap.exists()) setDesignSizes(docSnap.data()?.design_sizes?.map(size => ({value: size, label: size})))
      })
  }, [])

  // Methods
  async function onSubmit(data) {
    try {
      setIsLoading(true)
      const taskData = new Task(data)
      taskData.validate()
      const taskRef = doc(firestore, "tasks", taskData.data.id)
      await update(taskRef, taskData.data)
      setIsLoading(false)
      setIsOpen(false)
    } catch (err) {
      setIsLoading(false)
      await swal('Warning', err.message, 'warning')
    }
  }

  const handleCancel = async (e) => {
    e?.preventDefault()
    if(isDirty && !confirm('Are you sure you want to cancel the edits you have made?')) {
      return;
    }
    await reset();
    setIsOpen(false)
  }

  useButtonListener(handleCancel, 27, [formState])


  useEffect(() => {
    dispatch(fetchUsersByRole('member', currentUser?.data?.members))
  }, [])
  const members = useSelector(selectUsersByRole('member'))

  return(
    <div className='modal h-modal is-big is-active'>
      <div className="modal-background h-modal-close"/>
      <div className="modal-content">
        <form className="modal-card" onSubmit={handleSubmit(onSubmit)}>
          <header className="modal-card-head">
            <h3>Edit Task</h3>
            <a href="#" className={`h-modal-close ml-auto ${isLoading && 'is-disabled'}`}
               onClick={() => setIsOpen(false)}>
              <i className="bi bi-x"/>
            </a>
          </header>
          <div className="modal-card-body">
            <div className="inner-content">
              <div className="tabs-wrapper">
                <div className="tabs-inner">
                  <div className="tabs is-centered">
                    <ul>
                      <li data-tab="basic-details" className={`${activeTab === 'basic-details' && 'is-active'}`}>
                        <a href="#" onClick={() => { setActiveTab('basic-details') }}
                           className={`${ isLoading && 'is-disabled'}`}>
                          <i className="bi bi-pencil-square mr-2"/>
                          <span>Basic Details</span>
                        </a>
                      </li>
                      <li data-tab="files-&-records" className={`${activeTab === 'files-&-records' && 'is-active'}`}>
                        <a href="#" onClick={() => { setActiveTab('files-&-records') }}
                           className={`${ isLoading && 'is-disabled'}`}>
                          <i className="fas fa-paperclip"/>
                          <span>Files and Records</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="basic-details" className={`tab-content ${activeTab === 'basic-details' && `is-active`}`}>
                  <div className="modal-form">
                    <div className="columns is-multiline">
                      <Input label="Title" placeHolder="Ex: A white panner" name="title" helper="Optional"
                             register={register} />

                      <div className="column is-6">
                        <div className="field">
                          <label>Design Sizes</label>
                          <div className="control">
                            <MultiSelectPicker
                              control={control} options={designSizes} name="design_sizes"
                              args={{validation: {required: true}}}
                            />
                          </div>
                          <p className="help text-danger">Mandatory</p>
                        </div>
                      </div>
                      <Input label="Content" placeHolder="Details about the task..." name="content" helper="Mandatory"
                             helperClass="help text-danger" type="textarea"
                             options={{rows: 3, required: true}}
                             register={register} validation={{required: true}} size="12"
                      />
                      <Input label="Notes" placeHolder="Additional information about the task..." name="notes"
                             helper="Optional" type="textarea" options={{rows: 2}}
                             register={register} size="12"
                      />
                      <Input label="Admin Notes" placeHolder="Admin notes about the task" name="admin_notes"
                             helper="Optional" type="textarea" options={{rows: 3}}
                             register={register} size="12"
                      />
                      <Input label="Folder Name" placeHolder="Ex: Assets" name="folder_name" helper="Optional"
                             register={register} />
                      <SelectInput size="6" label={'Status'} name={'status'} register={register} control={control}
                                   defaultName={task.status}
                                   helper={'Change task status'}
                                   options={Object.values(Task.STATUS).map(status => ({name: status, value: status}))}
                      />
                      <div className="column is-12">
                        <div className="field">
                          <label>Assigned Members</label>
                          <div className="control">
                            <MultiSelectPicker
                              control={control} name="assignees_ids"
                              options={members.map(m => ({value: m.id, label: m.displayName}))}
                              selectArgs={{menuPlacement: 'top'}}
                            />
                          </div>
                          <p className="help">Optional</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {activeTab === 'files-&-records' && <MediaTab task={task} setModalIsLoading={setIsLoading} />}
              </div>
            </div>
          </div>
          <div className="modal-card-foot is-end">
            <button className={`button h-button is-raised h-modal-close ${isLoading && 'is-loading'}`}
                    onClick={handleCancel} type="reset">
              Cancel
            </button>
            <button className={`button h-button is-primary is-raised ${isLoading && 'is-loading'}`}>
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
