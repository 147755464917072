import Calendar from 'components/Calendar';
import {useAuth} from 'contexts/AuthContext';
import selectResource from 'hooks/selectResource';
import useDropdown from 'hooks/useDropdown';
import {useState} from 'react';
import {fetchAllPlans, selectPlans} from 'slices/planSlice';
import {fetchUsersByRole, selectUsersByRole} from 'slices/userSlice';
import CreatePlan from 'views/plans/CreatePlan';
import EditPlan from 'views/plans/EditPlan';

export default function PlansIndex() {

  const [createPlansIsOpen, setCreatePlansIsOpen] = useState(false)
  const [editPlansIsOpen, setEditPlansIsOpen] = useState(false)
  const [defaultDateRange, setDefaultDateRange] = useState({
    start: null,
    end: null
  })
  const [selectedPlan, setSelectedPlan] = useState({})
  const [selectedClientIds, setSelectedClientIds] = useState([])
  const [clientsDropdownIsOpen, toggleClientsDropdown] = useDropdown()
  const {currentUser} = useAuth()

  const onSelectSlot = (start, end) => {
    setDefaultDateRange({start, end})
    setCreatePlansIsOpen(true)
  }

  const onSelectEvent = (event) => {
    setSelectedPlan(event)
    setEditPlansIsOpen(true)
  }

  const renderInActiveClients = () => {
    return clients.filter(client => !selectedClientIds.includes(client?.id)).map( (client, index) => (
        <a href="#" className="dropdown-item font-size-base is-flex hint--primary hint--top"
           data-hint={`${client?.displayName} - ${client?.['abbreviation']} - ${client?.email}`} key={index}
           onClick={() => setSelectedClientIds(clientIds => clientIds.concat(client.id))}>
          <div className="h-avatar is-small is-bordered">
            <img className={`avatar avatar-thumb ${clients.includes(client?.id) && 'is-active'}`}
                 src={client?.photoURL || "https://via.placeholder.com/150x150"} alt="" />
          </div>
          <div className="ml-2 my-auto">
            {client.displayName}
          </div>
        </a>
      )
    )
  }

  const renderActiveClients = () => {
    return clients.filter(client => selectedClientIds.includes(client?.id)).map( (client, index) => (
      <div className="h-avatar is-small hint--primary hint--top" key={index}
           data-hint={`${client?.displayName} - ${client?.['abbreviation']} - ${client?.email}`}
           onClick={() => setSelectedClientIds(clientIds => clientIds.filter(clientId => clientId !== client.id))}>
        <img className='avatar avatar-thumb' src={client?.photoURL || "https://via.placeholder.com/150x150"} alt=""/>
      </div>
    ))
  }

  const plans = selectResource(fetchAllPlans(currentUser?.data?.clients), selectPlans, [selectedClientIds])
  const clients = selectResource(fetchUsersByRole('client', currentUser?.data?.clients), selectUsersByRole('client'))

  return(
    <div className="page-content-inner">
      <div className="column is-flex-inline has-gaps">
        <button className="button h-button is-danger mt-3 is-outlined" onClick={toggleClientsDropdown}>Clients</button>
        <div className={`dropdown is-dots dropdown-trigger ${clientsDropdownIsOpen && 'is-active'}`}>
          <div className="dropdown-menu" role="menu">
            <div className="dropdown-content">
              {renderInActiveClients()}
            </div>
          </div>
        </div>
        <div className="column is-flex is-one-third">
          <div className="avatar-stack" style={{marginLeft: 'unset', marginRight: '16px'}}>
            {renderActiveClients()}
          </div>
        </div>
      </div>
      <div style={{height: '80vh', borderRadius: '5px'}}>
        <Calendar
          events={selectedClientIds.length === 0 ? plans :
            plans.filter(plan => selectedClientIds.includes(plan.client_id))}
          onSelectSlot={onSelectSlot}
          onSelectEvent={onSelectEvent}
        />
      </div>
      {createPlansIsOpen && <CreatePlan toggleIsOpen={setCreatePlansIsOpen}
                                        defaultStartDate={defaultDateRange.start}
                                        defaultEndDate={defaultDateRange.end}
      />}
      {editPlansIsOpen && <EditPlan toggleIsOpen={setEditPlansIsOpen} plan={selectedPlan} />}
    </div>
  )
}
